.heroSection {
  background-color: #d7f3ff;
  display: flex;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
.leftSection {
  margin-top: 90px;
  margin-left: 63px;
  max-width: 45%;
  @media (max-width: 767px) {
    max-width: unset;
    margin: 30px;
  }
}
.title {
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    & p {
      letter-spacing: 1px;
      font-size: 48px;
    }
  }
  & p {
    color: #000;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 62.5px;
    letter-spacing: 4px;
    margin: 0;
  }
  & span {
    /* color: var(--marketplaceColor); */
    /* font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 62.5px; */
  }
}

.subTitle {
  color: var(--defaultFontColor);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
  max-width: 564px;
}

.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  & button {
    border: 0;
    border-radius: 5px;
    padding: 15px 25px;
    cursor: pointer;
    color: var(--marketplaceColor);
    background-color: #fff;
    font-weight: 500;
  }
  & button:first-child {
    background-color: var(--marketplaceColor);
    color: #fff;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
}

.searchIconContainer {
  right: 0px;
  position: absolute;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--marketplaceColor);
  height: 100%;
  display: flex;
  align-items: center; /* Vertically center the icon within the container */
  right: 0;
  padding: 0 15px;
  cursor: pointer;
}

.searchIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  fill: none;
}

.postListingLink {
  color: var(--defaultFontColor);

  /* Subtitle Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;

  & span {
    color: var(--defaultFontColor);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.rightSection {
  position: relative;
  /* width: 580px; */
  height: 580px;
  margin: 90px 0 0;
  @media (max-width: 767px) {
    width: 94%;
    height: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

/* Style the HeroDog SVG image */
.heroIcon {
  width: 100%; /* Adjust the size as needed */
  height: auto;
  position: relative;
  z-index: 1; /* Place the SVG image above the circle */
  padding: 0 120px 0 50px;
  @media (max-width: 1199px) {
    padding: 0 50px;
  }
  @media (max-width: 575px) {
    width: 70%;
    padding: 0;
    margin: 0 auto;
    display: block;
  }
}

.popularSearchContainer {
}
.sections {
  margin: 70px auto 70px;
  max-width: 1200px;
  @media (max-width: 576px) {
    margin: 30px auto;
  }
}
.sectionTitle {
  color: var(--defaultFontColor);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 576px) {
    text-align: center;
    margin-bottom: 30px;
  }
}
.sectitwithanc {
  display: flex;
  justify-content: space-between;
  & a {
    font-size: 20px;
    color: #fff;
    background-color: var(--marketplaceColor);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 20px;
    & a {
      margin: auto;
    }
  }
}
.popularMainSection {
  display: flex;
  margin-top: 48px;
  justify-content: space-around;
  @media (max-width: 767px) {
    margin-top: 20px;
    flex-wrap: wrap;
  }
}

.cardContainer {
  margin-right: 18px;
  border-radius: 8px;
  width: 303px; /* Set your card width */
  height: 318px; /* Set your card height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &div:hover {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    margin: 0 0 20px;
  }
}

.cardIcon {
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.cardLabel {
  margin-top: 33px;
  color: var(--defaultFontColor);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.exploreContainer {
}

.exploreIconContainer {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    margin-top: 0px;
  }
}

.firstRow,
.secondRow {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  gap: 20px;
  row-gap: 40px;
  flex-wrap: wrap;
  & svg {
    fill: none;
  }
}

.iconGrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-basis: 17%;
  @media (max-width: 767px) {
    flex-basis: 30%;
  }
  @media (max-width: 575px) {
    flex-basis: 47%;
  }
  &:hover {
    & div {
      &::before {
        width: 55px;
        background-color: var(--marketplaceColor);
      }
    }
  }
}

.iconLabel {
  margin-top: 15px;
  color: var(--defaultFontColor);
  text-align: center;
  /* Subtitle Semi-Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.iconLabel::before {
  content: '';
  display: flex;
  width: 35px;
  height: 2px;
  background-color: #b9b9b9;
  margin: 18px auto;
  transition: width 0.5s ease;
}

.descriptionContainer {
  border-radius: 5px;
  background: #f1fbff;
  margin: 114px auto;
  max-width: 1200px;
  padding: 66px 73px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 767px) {
    margin: 50px auto;
    padding: 30px 35px;
    flex-direction: column-reverse;
  }
}

.headingSection {
  & h2 {
    /* padding-top: 66px; */
    color: var(--defaultFontColor);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.listSection {
  margin-top: 44px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 60px;

  justify-content: center;
  @media (max-width: 576px) {
    justify-content: space-evenly;
  }
}
.pointContainer {
  margin-bottom: 37px;
}

.lbl {
  color: var(--defaultFontColor);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & span {
    color: var(--marketplaceColor);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
  }
}

.dtls {
  color: var(--defaultFontColor);

  /* Subtitle Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.descRight {
  margin-left: 25px;
  @media (max-width: 767px) {
    margin-left: 0px;

    & > div > div {
      width: 100%;
      margin: 20px 0;
    }
  }
}

.testimonialContainer {
  padding: 40px 33px;
  @media (min-width: 1400px) {
  }
  @media (max-width: 767px) {
    padding: 30px 35px;
  }
  @media (max-width: 576px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
}
.actorLevelsSec {
  background: var(--marketplaceColor);
  max-width: unset;
}
.guideContainer {
  border-radius: 5px;
  color: #fff;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  position: relative;
  background: var(--marketplaceColor);
  max-width: 1200px;
  margin: auto;
  @media (max-width: 767px) {
    margin: 50px auto;
    padding: 30px 35px;
    flex-direction: column;
  }
  & img {
    position: absolute;
    right: 35px;
    max-width: 44%;
    transform: translate(0, -50%);
    top: 57%;
    @media (min-width: 576px) and (max-width: 767px) {
      max-width: 30%;
    }
    @media (max-width: 575px) {
      max-width: unset;
      position: unset;
      transform: unset;
    }
  }
  & .elevpdesc {
    line-height: 20px;
    font-size: 15px;
    width: 80%;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
}
.elevp {
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  width: 85%;
  @media (max-width: 575px) {
    text-align: center;
  }
}
.guideLeft {
  max-width: 62%;
  padding: 20px 0 40px 30px;

  @media (max-width: 767px) {
    padding: 30px;
    /* max-width: 100%; */
  }
  @media (max-width: 575px) {
    padding: 20px 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & a {
    color: #000;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    padding: 12px 15px;
    font-weight: 500;
  }
}
.dummyGuideLeft {
  max-width: 70% !important;
  @media (max-width: 576px) {
    max-width: 100% !important;
  }
}
.cenf {
  display: flex;
  align-items: flex-start;
  & img {
    all: unset;
    margin: 7px 14px 0 0;
    @media (max-width: 576px) {
      width: 30px;
      height: 30px;
    }
  }
}
.benfdv {
  margin-top: 15px;
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  max-width: 70%;

  @media (max-width: 576px) {
    max-width: 100%;
  }

  & p:first-child {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin: 0px;
    padding-bottom: 0;
  }
  & p:last-child {
    margin: 0;
    font-family: var(--fontFamily);
    font-size: 14px;
    line-height: 22px;
    padding: 0;
  }
}
.simpbook {
  padding-left: 15px;
  /* max-width: 100%; */
  &::before {
    display: none !important;
  }
}
.catImg {
  bottom: 0 !important;
  top: unset !important;
  transform: unset !important;
  right: 376px !important;
  @media (max-width: 576px) {
    width: 150px;
    margin: auto;
  }
}
.clapperImg {
  right: 0 !important;
  top: 40% !important;
}
.dogImg {
  top: unset !important;
  transform: unset !important;
  bottom: 10px !important;
  right: 235px !important;
}
.parotImg {
  top: unset !important;
  transform: unset !important;
  right: 55px !important;
  bottom: 10px !important;
}
.rightImg {
  margin-top: 41px;
  width: 100%;
  height: 721px;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 767px) {
    height: unset;
    margin-top: 0px;
  }
}

.guideHeadingSection {
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.guidePointContainer {
  display: flex;
  & div {
    color: #fff;
  }
  & span {
    width: 30px;
    height: 29.013px;
    flex-shrink: 0;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
  }
}
.pointSection {
  margin-left: 23px;
  margin-bottom: 51px;
}

.guidePointLbl {
  color: #fff;

  /* Title Bold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.guidePointDtls {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.listingContainer {
  @media (max-width: 767px) {
    margin: 50px auto;
    padding: 30px 35px;
  }
}

.listingCardContainer {
  margin-right: 18px;
  border-radius: 8px;
  width: 405px; /* Set your card width */
  height: 339px; /* Set your card height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &div:hover {
    text-decoration: underline;
  }
  @media (max-width: 576px) {
    margin: 0;
  }
}
.popularSearchContainer {
}
.quoteSection {
  display: flex !important;
  align-items: center;
  /* justify-content: center; */
  /* height: 70vh; */
  @media (max-width: 767px) {
    flex-direction: column;
    height: unset;
  }
}
.brandquoteSection {
  display: flex !important;
  align-items: center;
  width: 200px;
  height: 200px;
  /* justify-content: center; */
  /* height: 70vh; */
  @media (max-width: 767px) {
    flex-direction: column;
    height: unset;
  }
}
.mobdiv {
  display: flex;
  align-items: flex-end;
  position: relative;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
  & img {
    position: unset;
    max-width: 200px;
  }
  & img:last-child {
    position: absolute;
    max-width: 120px;
    top: -25px !important;
  }
}
.quoteImg {
  /* max-width: 40%; */
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  margin-right: 20px;
}

.quoteTxt {
  max-width: 60%;
  text-align: left;
  margin: auto;
  & p {
    font-size: 19px;
    line-height: 30px;
    margin-bottom: 25px;
  }
  & footer {
    font-size: 19px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    max-width: unset;
    margin-top: 30px;
  }
}

.player {
  position: relative;
  width: 641px;
  height: fit-content;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.pltreviews {
  /* margin-top: 100px; */
}
.reviewImg {
  position: relative;
  & .landingPageReviwImgstars {
    position: absolute;
  }
  & .revImg {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 3px solid yellow;
    object-fit: cover;
  }
  &::after {
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    top: 46%;
    rotate: 45deg;
    transform: translate(0, -50%);
    right: -11px;
    border: 20px solid #fff;
    background-color: transparent;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    @media (max-width: 576px) {
      display: none;
      rotate: 135deg;
      top: unset;
      left: 40%;
      right: unset;
      transform: translate(-50%, 0);
    }
  }
}
.asSeenOnContainer {
  display: flex;
  & > span {
    display: flex;
    align-items: center;
    font-weight: 700;
    white-space: nowrap;
    color: #000;
    font-size: 20px;
  }
  & .asSeenOnImgDiv {
    display: flex;
    width: 100%;
    & img {
      flex-basis: 20%;
      width: 120px;
      height: 100px;
      object-fit: contain;
      /* background-color: #fff; */
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    & .asSeenOnImgDiv {
      max-width: 100%;
      overflow: auto;
      gap: 20px;
    }
    & > span {
      margin: auto;
    }
  }
}
.descLeft {
  width: 100%;
  & h2 {
    font-size: 30px;
    text-align: center;
    color: #fff;
    line-height: 42px;
  }
}

.pointContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;
  gap: 20px;
  max-width: 250px;
  flex-basis: 22%;
  align-items: center;
  color: #fff;
  & svg {
    stroke-width: 1px;
    & path {
      stroke: #fff;
    }
  }
  & img {
    all: unset;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  & p {
    text-align: center;
    margin: 0;
  }
  @media (max-width: 991px) {
    flex-basis: 40%;
    max-width: 40%;
  }
}
.lbl {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  & span {
    color: var(--marketplaceColor);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
  }
}
.popularMainSection {
  display: flex;
  margin-top: 48px;
  justify-content: space-around;
  @media (max-width: 767px) {
    margin-top: 20px;
    flex-wrap: wrap;
  }
}
.slider {
  /* width: 100%; */
  width: calc(100% - 100px);
}
.seaconSearchCOnt {
  & > button {
    background-color: #fff !important;
    color: var(--marketplaceColor) !important;
  }
}
.thirsearchcon {
  justify-content: center !important;
  margin-top: 20px;
}
.headTags {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 6px;
  border-radius: 30px;
  font-size: 18px;
  gap: 10px;
  color: #fff;
  z-index: 1;
  & svg {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 575px) {
    font-size: 15px;
    padding: 2px 10px 2px 6px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }
}
.headtag1 {
  left: 20px;
  top: 18%;
  background-color: var(--marketplaceColor);
}
.headtag2 {
  top: 34%;
  right: 0;
  background-color: #eb2978;
}
.headtag3 {
  top: 63%;
  left: 0;
  background-color: #fbca01;
}
.headtag4 {
  top: 84%;
  right: 0;
  background-color: var(--marketplaceColor);
}
.simpBookCont {
  max-width: unset;
}
