.slick-slide {
  transform: scale(0.85);
  pointer-events: none;
  transition: transform 0.6s linear;
}

.slick-center {
  transform: scale(1);
  pointer-events: all;
}

/* Customize the color of the slider buttons */
/* .slick-prev,
.slick-next {
  background-color: #3498db; 
  color: #fff;
} */

/* Hover effect for the buttons (optional) */
/* .slick-prev:hover,
.slick-next:hover {
  background-color: #2980b9;
} */
